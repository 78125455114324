import React from 'react';
import LiveKitAudioCall from './LiveKitAudioCall';
import LiveKitAudioCall_NewUI from './LiveKitAudioCall_NewUI';
import Noxvo from './Noxvo';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';


const App = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <main>
          {/* <LiveKitAudioCall /> */}
          <LiveKitAudioCall_NewUI />
          {/* <Noxvo /> */}
        </main>
      </div>
      <ToastContainer />
    </QueryClientProvider>
  );
};

export default App;
