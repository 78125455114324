import { useMutation, useQuery } from "@tanstack/react-query";
import apiRoutes from "./apiroutes";
import { toast } from "react-toastify";
// import Axios from "../service/axiosConfig";
import axios from "axios";

export const GetAgentList = (onSuccess = () => { }, onError = () => { }) => {
    // const dispatch = useDispatch();
    const BASE_URL_2 = process.env.REACT_APP_BASE_URL_2;
    return useQuery({
        queryKey: ["users"],
        queryFn: async () => {
            let resp = await axios.get(BASE_URL_2 + apiRoutes('AGENT'));
            return resp.data
        },
        onSuccess: (resp) => {
            if (resp && resp?.respcode === "200") {
                // dispatch(getLanguagesReducerAction(resp?.languages));
                onSuccess(resp);
            } else {
                toast.error(resp?.respdesc);
            }
        },
        onError: (err) => {
            if (err.response) {
                toast.error(
                    err?.response?.data?.errors?.length &&
                    err?.response?.data?.errors[0]?.message
                );
            } else {
                toast.error(err.message);
            }
            onError(err?.response?.data);
        },
        // retry: 1,
        // enabled: true,
        // refetchInterval: 2000,
    });
};
export const GetLanguageList = (onSuccess = () => { }, onError = () => { }) => {
    const BASE_URL_2 = process.env.REACT_APP_BASE_URL_2;
    return useMutation({
        mutationFn: async (selectedAgentId) => {
            let resp = await axios.get(BASE_URL_2 + apiRoutes('AGENT_LANGUAGES'), {
                params: {
                    'filter[agent_id][_eq]': selectedAgentId,
                    'fields': 'language_id.*'
                }
            });
            return resp.data
        },
        onSuccess: (resp) => {
            if (resp) {
                onSuccess(resp);
            } else {
                toast.error(resp?.respdesc);
            }
        },
        onError: (err) => {
            if (err.response) {
                toast.error(
                    err?.response?.data?.errors?.length &&
                    err?.response?.data?.errors[0]?.message
                );
            } else {
                toast.error(err.message);
            }
            onError(err?.response?.data);
        },
        // retry: 1,
        // enabled: true,
        // refetchInterval: 2000,
    });
};

