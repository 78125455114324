import React, { useState, useEffect, useRef } from 'react';
import { Room, RoomEvent, Track } from 'livekit-client';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Setup Web Speech API with support check
let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition;

if (SpeechRecognition) {
  recognition = new SpeechRecognition();
  recognition.continuous = true;
  recognition.interimResults = true;
} else {
  console.warn('Web Speech API is not supported in this browser.');
}

const LIVEKIT_URL = process.env.REACT_APP_LIVEKIT_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const TOKEN_ENDPOINT = `${BASE_URL}/get-token`;
const AUDIO_PROCESS_ENDPOINT = `${BASE_URL}/process-audio`;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
`;

const Button = styled.button`
  position: relative;
  background-color: ${(props) => (props.end ? '#d9534f' : '#5cb85c')};
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.end ? '#c9302c' : '#4cae4c')};
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ButtonLoader = styled.span`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid white;
  border-top: 2px solid #4cae4c;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: translateY(-50%) rotate(0deg); }
    100% { transform: translateY(-50%) rotate(360deg); }
  }
`;

const StatusMessage = styled.div`
  margin: 20px;
  font-size: 18px;
  color: #555;
  font-weight: bold;
`;

const LiveKitAudioCall = () => {
  const [room, setRoom] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const localAudioRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const audioRef = useRef(null);
  const greetingAudioRef = useRef(new Audio('introduction_clip_cut_te.mp3')); // Add the path to your greeting audio file

  useEffect(() => {
    if (room) {
      room.on(RoomEvent.Disconnected, handleDisconnect);
      room.on(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakersChanged);
    }

    // Initialize speech recognition
    if (recognition) {
      recognition.onstart = () => {
        console.log('Speech recognition started');
      };

      recognition.onend = () => {
        console.log('Speech recognition ended');
      };

      recognition.onresult = (event) => {
        let interimTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            console.log('Final Transcript: ', event.results[i][0].transcript);
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }
        console.log('Interim Transcript: ', interimTranscript);
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error', event.error);
        // Automatically restart recognition in case of an error
        if (event.error === 'network' || event.error === 'audio-capture') {
          recognition.stop();
          recognition.start();
        }
      };
    }

    return () => {
      if (room) {
        room.disconnect();
      }
    };
  }, [room]);

  const handleActiveSpeakersChanged = (speakers) => {
    const localParticipant = room.localParticipant;
    const isLocalSpeaking = speakers.some((speaker) => speaker.sid === localParticipant.sid);

    setIsSpeaking(isLocalSpeaking);

    if (isLocalSpeaking) {
      console.log('Local participant is speaking');
      setStatusMessage("Customer is speaking...");
      if (audioRef.current) {
        audioRef.current.pause();
      }
      if (!isRecording) {
        startRecording();
        if (recognition) recognition.start();
      }
    } else {
      console.log('Local participant is not speaking');
      if (isRecording) {
        stopRecording();
        if (recognition) recognition.stop();
      }
    }
  };

  const handleTrackSubscribed = (track, publication) => {
    console.log('Track subscribed:', track);
    if (track.kind === Track.Kind.Audio) {
      const element = track.attach();
      remoteAudioRef.current.appendChild(element);
    }
  };

  const handleTrackUnsubscribed = (track, publication) => {
    console.log('Track unsubscribed:', track);
    track.detach().forEach((element) => element.remove());
  };

  const handleDisconnect = () => {
    console.log('Disconnected from room');
    setStatusMessage('Disconnected from room');
    if (isRecording && mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
    if (recognition) recognition.stop(); // Ensure stopping speech recognition on disconnect
  };

  const startRecording = () => {
    console.log('Starting recording');
    chunksRef.current = []; // Reset chunks on each recording start

    if (mediaRecorderRef.current) {
      const state = mediaRecorderRef.current.state;
      if (state === 'inactive' || state === 'paused') {
        mediaRecorderRef.current.start();
        setIsRecording(true);
      } else {
        console.warn(`MediaRecorder is not in a state to start recording. Current state: ${state}`);
      }
    }
  };

  const stopRecording = () => {
    if (isRecording && mediaRecorderRef.current) {
      const state = mediaRecorderRef.current.state;
      if (state === 'recording') {
        console.log('Stopping recording');
        mediaRecorderRef.current.stop();
        setIsRecording(false);
      } else {
        console.warn(`MediaRecorder is not in a state to stop recording. Current state: ${state}`);
      }
    }
  };

  const processAudio = async (audioBlob) => {
    console.log('Uploading audio for processing');
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio/webm');

    try {
      const response = await fetch(AUDIO_PROCESS_ENDPOINT, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      const { audioBase64, chatResponse } = result;

      // Convert Base64 string back to binary data
      const audioBytes = atob(audioBase64);
      const byteNumbers = new Array(audioBytes.length);
      for (let i = 0; i < audioBytes.length; i++) {
        byteNumbers[i] = audioBytes.charCodeAt(i);
      }
      const audioArrayBuffer = new Uint8Array(byteNumbers);

      // Create a Blob and play it
      const audioBlob = new Blob([audioArrayBuffer], { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audioRef.current = audio;

      // Set status message to "Agent is speaking..."
      setStatusMessage("Agent is speaking...");

      // Add event listener to reset status message when audio ends
      audio.addEventListener('ended', () => {
        setStatusMessage('');
      });

      // Play the audio if not speaking
      if (!isSpeaking) {
        audio.play();
      }

      console.log('Processed and played audio');
    } catch (error) {
      toast.error("Failed to process audio");
      console.error('Error processing audio:', error);
    }
  };

  const captureAndSendAudio = async () => {
    console.log('Starting capture and send audio');
    if (localAudioRef.current && localAudioRef.current.srcObject) {
      const initializeMediaRecorder = () => {
        mediaRecorderRef.current = new MediaRecorder(localAudioRef.current.srcObject);

        mediaRecorderRef.current.ondataavailable = (event) => {
          console.log('Data available:', event.data.size);
          if (event.data.size > 0) {
            chunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.onstop = async () => {
          console.log('Stopping media recorder');
          if (chunksRef.current.length > 0) {
            const audioBlob = new Blob(chunksRef.current, { type: 'audio/webm' });
            chunksRef.current = []; // Clear chunks after processing
            await processAudio(audioBlob);
          }
          // Delay to ensure MediaRecorder is properly reset before starting new recording
          setTimeout(() => {
            initializeMediaRecorder();
            startRecording(); // Restart recording after processing
          }, 100);
        };
      };

      initializeMediaRecorder();
      startRecording();
    }
  };

  const startCall = async () => {
    console.time('startCall');
    setIsLoading(true);
    try {
      const tokenResponse = await fetch(TOKEN_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ identity: 'user', room: 'room_name' }),
      });
      const tokenData = await tokenResponse.json();
      const token = tokenData.token;

      const room = new Room({ adaptiveStream: true, dynacast: true });

      room
        .on(RoomEvent.TrackSubscribed, handleTrackSubscribed)
        .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
        .on(RoomEvent.Disconnected, handleDisconnect);

      await room.prepareConnection(LIVEKIT_URL, token);
      await room.connect(LIVEKIT_URL, token);

      setStatusMessage(`Connected to room ${room.name}`);

      const localAudioTrackPublication = await room.localParticipant.setMicrophoneEnabled(true);
      if (localAudioTrackPublication && localAudioTrackPublication.track) {
        const localAudioTrack = localAudioTrackPublication.track;

        console.log('Local audio track:', localAudioTrack);
        const element = localAudioTrack.attach();
        element.muted = true; // Ensure local audio is muted
        localAudioRef.current.appendChild(element);

        const mediaStream = new MediaStream([localAudioTrack.mediaStreamTrack]);
        localAudioRef.current.srcObject = mediaStream; // Attach the media stream to the local audio ref
        captureAndSendAudio();
      }

      setRoom(room);
      toast.success("Call started successfully!");

      // Play greeting audio
      if (greetingAudioRef.current) {
        greetingAudioRef.current.play();
      }
    } catch (error) {
      toast.error("Failed to start the call");
      console.error('Error connecting to room:', error);
      setStatusMessage('Failed to connect to room');
    } finally {
      setIsLoading(false);
      console.timeEnd('startCall');
    }
  };

  const stopCall = () => {
    console.log('Stopping call');

    if (room) {
      room.disconnect();
      setRoom(null);
      setStatusMessage('Call ended');
    }

    stopRecording();
    toast.info("Call ended.");
    
    // Refresh the page
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Container>
      <Button onClick={startCall} disabled={room !== null || isLoading}>
        Start Call {isLoading && <ButtonLoader />}
      </Button>
      <Button onClick={stopCall} end>Stop Call</Button>
      <StatusMessage>{statusMessage}</StatusMessage>
      <audio ref={localAudioRef} autoPlay muted></audio>
      <div ref={remoteAudioRef}></div>
      <ToastContainer />
    </Container>
  );
};

export default LiveKitAudioCall;
