import React, { useState, useEffect, useRef } from 'react';
import { Room, RoomEvent, Track } from 'livekit-client';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Phone, PhoneOff } from 'lucide-react';
import axios from 'axios';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { GetAgentList, GetLanguageList } from './apis/agents';


// Setup Web Speech API with support check
let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition;

if (SpeechRecognition) {
  recognition = new SpeechRecognition();
  recognition.continuous = true;
  recognition.interimResults = true;
} else {
  console.warn('Web Speech API is not supported in this browser.');
}

const LIVEKIT_URL = process.env.REACT_APP_LIVEKIT_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const TOKEN_ENDPOINT = `${BASE_URL}/get-token`;
const AUDIO_PROCESS_ENDPOINT = `${BASE_URL}/process-audio`;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
`;

const Button = styled.button`
  position: relative;
  background-color: ${(props) => (props.end ? '#d9534f' : '#5cb85c')};
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.end ? '#c9302c' : '#4cae4c')};
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ButtonLoader = styled.span`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid white;
  border-top: 2px solid #4cae4c;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: translateY(-50%) rotate(0deg); }
    100% { transform: translateY(-50%) rotate(360deg); }
  }
`;

const StatusMessage = styled.div`
  margin: 20px;
  font-size: 18px;
  color: #FFFFF;
  font-weight: bold;
`;

const LiveKitAudioCallNewUi = () => {
  const [room, setRoom] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const localAudioRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const audioRef = useRef(null);
  const greetingAudioRef = useRef(new Audio('introduction_clip_cut_te.m4a')); // Add the path to your greeting audio file
  const [isCallActive, setIsCallActive] = useState(false)
  const [timer, setTimer] = useState(0)
  const [speakingState, setSpeakingState] = useState('')
  const [selectedAgentId, setSelectedAgentId] = useState('')
  const [selectedLanguageId, setSelectedLanguageId] = useState('')
  console.table({ "selectedAgentId": selectedAgentId, "selectedLanguageId": selectedLanguageId });
  // console.log(selectedLanguageId, "selectedLanguageId");

  const [agentLanguages, setAgentLanguages] = useState([]);

  const BASE_URL_2 = process.env.REACT_APP_BASE_URL_2;

  const agents = [
    { name: 'SK Contructions - Sri Krishna', type: 'Real state agent', languages: ['english', 'telugu'] },
    { name: 'Flynow Consultant - Muzammil', type: 'Education Agent', languages: ['english', 'marathi'] },
    { name: 'Prathibha Hospital - Vamsi', type: 'Healthcare Agent', languages: ['hindi', 'marathi'] },
  ]

  const allLanguages = ['english', 'telugu', 'hindi', 'marathi']

  const agentsData = GetAgentList();
  const loadingAgentsData = agentsData?.isLoading;
  const fetchErrorAgentsData = agentsData?.error;

  // console.log(agentsData, "agentsData");
  // console.log(fetchErrorAgentsData, "fetchErrorAgentsData");
  // console.log(loadingAgentsData, "loadingAgentsData");

  const clearAgentsData = () => {
    QueryClient.invalidateQueries('agents');
    QueryClient.removeQueries('agents');
  };



  useEffect(() => {
    let interval = null
    if (isCallActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1)
      }, 1000)
    } else if (interval) {
      clearInterval(interval)
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [isCallActive])

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
  }

  // const agentLanguagesData = GetLanguageList();
  // console.log(agentLanguagesData, "agentLanguagesData");

  const agentLanguagesData = GetLanguageList();
  const loadingAgentsLanguagesData = agentLanguagesData?.isLoading;
  const fetchErrorLanguagesData = agentLanguagesData?.error;

  const handleAgentChange = async (e) => {
    e.preventDefault();
    const selectedAgentId = e.target.value;
    setSelectedAgentId(selectedAgentId);
    if (selectedAgentId) {
      agentLanguagesData.mutate(selectedAgentId);
    } else {
      setAgentLanguages([]);
    }
    setSelectedLanguageId('');
  };


  const handleLanguageChange = (e) => {
    const { value } = e.target;
    setSelectedLanguageId(value)
  }

  const isCallButtonEnabled = selectedAgentId && selectedLanguageId

  useEffect(() => {
    if (room) {
      room.on(RoomEvent.Disconnected, handleDisconnect);
      room.on(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakersChanged);
    }

    // Initialize speech recognition
    if (recognition) {
      recognition.onstart = () => {
        console.log('Speech recognition started');
      };

      recognition.onend = () => {
        console.log('Speech recognition ended');
      };

      recognition.onresult = (event) => {
        let interimTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            console.log('Final Transcript: ', event.results[i][0].transcript);
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }
        console.log('Interim Transcript: ', interimTranscript);
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error', event.error);
        // Automatically restart recognition in case of an error
        if (event.error === 'network' || event.error === 'audio-capture') {
          recognition.stop();
          recognition.start();
        }
      };
    }

    return () => {
      if (room) {
        room.disconnect();
      }
    };
  }, [room]);

  const handleActiveSpeakersChanged = (speakers) => {
    const localParticipant = room.localParticipant;
    const AUDIO_LEVEL_THRESHOLD = 0.125;
    

  // Check if the local participant is speaking and has an audio level above the threshold
  const isLocalSpeaking = speakers.some((speaker) => {
    console.log("speaker.audioLevel",speaker.audioLevel);

    return speaker.sid === localParticipant.sid && speaker.audioLevel > AUDIO_LEVEL_THRESHOLD;
  });

    setIsSpeaking(isLocalSpeaking);

    if (isLocalSpeaking) {
      console.log('Local participant is speaking');
      setStatusMessage("Customer is speaking...");
      setSpeakingState(prev => prev === 'Customer is speaking' ? 'Agent is speaking' : 'Customer is speaking')
      if (audioRef.current) {
        audioRef.current.pause();
      }
      if (!isRecording) {
        startRecording();
        if (recognition) recognition.start();
      }
    } else {
      console.log('Local participant is not speaking');
      if (isRecording) {
        stopRecording();
        if (recognition) recognition.stop();
      }
    }
  };

  const handleTrackSubscribed = (track, publication) => {
    console.log('Track subscribed:', track);
    if (track.kind === Track.Kind.Audio) {
      const element = track.attach();
      remoteAudioRef.current.appendChild(element);
    }
  };

  const handleTrackUnsubscribed = (track, publication) => {
    console.log('Track unsubscribed:', track);
    track.detach().forEach((element) => element.remove());
  };

  const handleDisconnect = () => {
    console.log('Disconnected from room');
    setStatusMessage('Disconnected from room');
    if (isRecording && mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
    if (recognition) recognition.stop(); // Ensure stopping speech recognition on disconnect
  };

  const startRecording = () => {
    console.log('Starting recording');
    chunksRef.current = []; // Reset chunks on each recording start

    if (mediaRecorderRef.current) {
      const state = mediaRecorderRef.current.state;
      if (state === 'inactive' || state === 'paused') {
        mediaRecorderRef.current.start();
        setIsRecording(true);
      } else {
        console.warn(`MediaRecorder is not in a state to start recording. Current state: ${state}`);
      }
    }
  };

  const stopRecording = () => {
    if (isRecording && mediaRecorderRef.current) {
      const state = mediaRecorderRef.current.state;
      if (state === 'recording') {
        console.log('Stopping recording');
        mediaRecorderRef.current.stop();
        setIsRecording(false);
      } else {
        console.warn(`MediaRecorder is not in a state to stop recording. Current state: ${state}`);
      }
    }
  };
  // clearAgentsData()

  const processAudio = async (audioBlob) => {
    console.log('Uploading audio for processing');
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio/webm');
    formData.append('agent_id', selectedAgentId);
    formData.append('language_id', selectedLanguageId);

    try {
      const response = await fetch(AUDIO_PROCESS_ENDPOINT, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      const { audioBase64, chatResponse } = result;

      // Convert Base64 string back to binary data
      const audioBytes = atob(audioBase64);
      const byteNumbers = new Array(audioBytes.length);
      for (let i = 0; i < audioBytes.length; i++) {
        byteNumbers[i] = audioBytes.charCodeAt(i);
      }
      const audioArrayBuffer = new Uint8Array(byteNumbers);

      // Create a Blob and play it
      const audioBlob = new Blob([audioArrayBuffer], { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audioRef.current = audio;

      // Set status message to "Agent is speaking..."
      setStatusMessage("Agent is speaking...");

      // Add event listener to reset status message when audio ends
      audio.addEventListener('ended', () => {
        setStatusMessage('');
      });

      // Play the audio if not speaking
      if (!isSpeaking) {
        audio.play();
      }

      console.log('Processed and played audio');
    } catch (error) {
      toast.error("Failed to process audio");
      console.error('Error processing audio:', error);
    }
  };

  const captureAndSendAudio = async () => {
    console.log('Starting capture and send audio');
    if (localAudioRef.current && localAudioRef.current.srcObject) {
      const initializeMediaRecorder = () => {
        mediaRecorderRef.current = new MediaRecorder(localAudioRef.current.srcObject);

        mediaRecorderRef.current.ondataavailable = (event) => {
          console.log('Data available:', event.data.size);
          if (event.data.size > 0) {
            chunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.onstop = async () => {
          console.log('Stopping media recorder');
          if (chunksRef.current.length > 0) {
            const audioBlob = new Blob(chunksRef.current, { type: 'audio/webm' });
            chunksRef.current = []; // Clear chunks after processing
            await processAudio(audioBlob);
          }
          // Delay to ensure MediaRecorder is properly reset before starting new recording
          setTimeout(() => {
            initializeMediaRecorder();
            startRecording(); // Restart recording after processing
          }, 100);
        };
      };

      initializeMediaRecorder();
      startRecording();
    }
  };

  const startCall = async () => {
    console.time('startCall');
    setIsCallActive(true)
    setIsLoading(true);
    try {
      const tokenResponse = await fetch(TOKEN_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ identity: 'user', room: 'room_name' }),
      });
      const tokenData = await tokenResponse.json();
      const token = tokenData.token;

      const room = new Room({ adaptiveStream: true, dynacast: true });

      room
        .on(RoomEvent.TrackSubscribed, handleTrackSubscribed)
        .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
        .on(RoomEvent.Disconnected, handleDisconnect);

      await room.prepareConnection(LIVEKIT_URL, token);
      await room.connect(LIVEKIT_URL, token);

      setStatusMessage(`Connected to room ${room.name}`);

      const localAudioTrackPublication = await room.localParticipant.setMicrophoneEnabled(true);
      if (localAudioTrackPublication && localAudioTrackPublication.track) {
        const localAudioTrack = localAudioTrackPublication.track;

        console.log('Local audio track:', localAudioTrack);
        const element = localAudioTrack.attach();
        element.muted = true; // Ensure local audio is muted
        localAudioRef.current.appendChild(element);

        const mediaStream = new MediaStream([localAudioTrack.mediaStreamTrack]);
        localAudioRef.current.srcObject = mediaStream; // Attach the media stream to the local audio ref
        captureAndSendAudio();
      }

      setRoom(room);
      toast.success("Call started successfully!");

      // Play greeting audio
      if (greetingAudioRef.current) {
        greetingAudioRef.current.play();
      }
    } catch (error) {
      toast.error("Failed to start the call");
      console.error('Error connecting to room:', error);
      setStatusMessage('Failed to connect to room');
    } finally {
      setIsLoading(false);
      console.timeEnd('startCall');
    }
  };

  const stopCall = () => {
    console.log('Stopping call');

    if (room) {
      room.disconnect();
      setRoom(null);
      setStatusMessage('Call ended');
      setIsCallActive(false)
      setTimer(0)
      setSpeakingState('')
    }

    stopRecording();
    toast.info("Call ended.");

    // // Refresh the page
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    // <Container>
    //   <Button onClick={startCall} disabled={room !== null || isLoading}>
    //     Start Call {isLoading && <ButtonLoader />}
    //   </Button>
    //   <Button onClick={stopCall} end>Stop Call</Button>
    //   <StatusMessage>{statusMessage}</StatusMessage>
    //   <audio ref={localAudioRef} autoPlay muted></audio>
    //   <div ref={remoteAudioRef}></div>
    //   <ToastContainer />
    // </Container>
    <>
      {loadingAgentsData &&
        <div className='flex items-center justify-center min-h-screen'>
          <ButtonLoader />
        </div>

      }
      {fetchErrorAgentsData &&
        <div className='flex items-center justify-center min-h-screen'>
          <div>Error: {fetchErrorAgentsData?.message}</div>
        </div>
      }
      {loadingAgentsLanguagesData &&
        <div className='flex items-center justify-center min-h-screen'>
          <ButtonLoader />
        </div>

      }
      {fetchErrorLanguagesData &&
        <div className='flex items-center justify-center min-h-screen'>
          <div>Error: {fetchErrorAgentsData?.message}</div>
        </div>
      }
      <div className="min-h-screen flex items-center justify-center p-3 bg-gradient-to-br from-blue-500 to-purple-600">
        <div className="relative w-full max-w-[360px] h-[640px] bg-gradient-to-br from-blue-500 to-purple-600 rounded-[40px] shadow-[0_0_0_10px_#1a1a1a,0_20px_40px_rgba(0,0,0,0.4)] overflow-hidden">
          {/* App Content */}
          <div className="flex flex-col h-full justify-between p-4">
            <div className="text-center">
              <h1 className="mb-4 font-['Roboto',sans-serif] font-bold text-4xl text-white">
                nexvo.ai
              </h1>
              <div className="bg-white rounded-full inline-flex justify-center items-center mb-3 w-20 h-20">
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="#667eea" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="#667eea" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <h2 className="text-white mb-4 text-2xl">AI Assistant</h2>
            </div>
            {!isCallActive && (
              <div className="mb-4 space-y-2">
                <select
                  className="w-full p-2 rounded-md bg-white text-gray-800"
                  onChange={handleAgentChange}
                  value={selectedAgentId}
                >
                  <option value="">Select Agent</option>
                  {agentsData?.data?.data?.map((agent, index) => (
                    <option key={index} value={agent?.id}>
                      {agent.name} - {agent.company_name}
                    </option>
                  ))}
                </select>

                <select
                  className="w-full p-2 rounded-md bg-white text-gray-800"
                  onChange={handleLanguageChange}
                  value={selectedLanguageId}
                  disabled={!selectedAgentId}
                >
                  <option value="">Select Language</option>
                  {agentLanguagesData?.data?.data?.map((language, index) => {
                    return (
                      <option
                        key={index}
                        value={language?.language_id?.id} // Make sure to use the correct value
                        disabled={language?.language_id?.status === "in-active"}
                      >
                        {language?.language_id?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            {isCallActive && (
              <div className="text-center mb-3">
                <p className="mb-0 text-white text-2xl font-bold shadow-white drop-shadow-lg">
                  Call Duration: {formatTime(timer)}
                </p>
                <p className="mb-0 text-white">{<StatusMessage>{statusMessage}</StatusMessage>}</p>
              </div>
            )}

            <div className="text-center">
              {!isCallActive ? (
                <button
                  className={`rounded-full flex justify-center items-center mx-auto w-[70px] h-[70px] ${isCallButtonEnabled
                    ? 'bg-green-500 hover:bg-green-600 animate-pulse'
                    : 'bg-gray-400 cursor-not-allowed'
                    }`}
                  onClick={startCall}
                  disabled={!isCallButtonEnabled}
                >
                  <Phone size={30} color="white" />
                </button>
              ) : (
                <button
                  className="rounded-full flex justify-center items-center mx-auto w-[70px] h-[70px] bg-red-500 hover:bg-red-600"
                  onClick={stopCall}
                >
                  <PhoneOff size={30} color="white" />
                </button>
              )}
            </div>
          </div>
        </div>
        <audio ref={localAudioRef} autoPlay muted></audio>
        <div ref={remoteAudioRef}></div>

        <style jsx>{`
      @keyframes pulse {
        0% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(72, 187, 120, 0.7);
        }
        70% {
          transform: scale(1.1);
          box-shadow: 0 0 0 10px rgba(72, 187, 120, 0);
        }
        100% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(72, 187, 120, 0);
        }
      }

      @media (max-width: 400px) {
        .min-h-screen {
          padding: 20px !important;
        }
      }
    `}</style>
      </div>
    </>
  );
};

export default LiveKitAudioCallNewUi;
